
export interface IQuestion {
	formQuestion: string;
	cardQuestion: string;
}

export const QuestionList: IQuestion[] = [
	{
		formQuestion: "Wat is het lievelingseten van je beer?",
		cardQuestion: "Eet graag",
	},
	{
		formQuestion: "Wat is het lievelingsliedje van je beer?",
		cardQuestion: "Zingt graag",
	},
	{
		formQuestion: "Wat is het favoriete spel van je beer?",
		cardQuestion: "Speelt graag",
	},
	{
		formQuestion: "Waar houdt je beer het meeste van?",
		cardQuestion: "Houdt van",
	},
];
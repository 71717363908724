import React, { useCallback } from "react"
import { useHistory } from "react-router-dom";
import { DatabaseContext } from "./DatabaseContext";
import { useRequiredContext, usePromise } from "../util";
import { CatchBearError } from "shared";
import { CenterContainer } from "./CenterContainer";
import { Box } from "./Box";
import { LoadSpinner } from "./LoadSpinner";
import { BearPawButton } from "./BearPawButton";

export interface ICatchBearProps {
	bearId: string | undefined;
}

export const CatchBear: React.FC<ICatchBearProps> = (props) => {

	const history = useHistory();

	const { bearId } = props;
	const database = useRequiredContext(DatabaseContext);

	const catchBear = useCallback(async () => {
		if (bearId === undefined) throw new Error(`Dit is geen geldige berenjacht URL.`);

		try {
			const bear = await database.backend.catchBear({ bearId: bearId });
			return bear;
		}
		catch (err) {
			console.log("ERROR FOUND!");
			console.log(err);
			if (err.code === CatchBearError.BearDeleted) throw new Error(`Helaas is deze beer ondertussen verwijderd door de eigenaar. Je kunt hem niet meer vangen!`);
			if (err.code === CatchBearError.AlreadyCaught) throw new Error(`Je hebt deze beer al eerder gevangen!`);
		}

		throw new Error(`Should never happen.`);
	}, [bearId, database]);

	const [bear, bearError, loadingBear] = usePromise(catchBear, null);
	console.log("Bear error: " + bearError + ", loading: " + loadingBear);

	if (loadingBear) {
		return <LoadSpinner />;
	}

	if (bearError !== null) {
		return (
			<CenterContainer>
				<Box>
					<p>
						{bearError.message}
					</p>
					<p>
						Klik op de berenpoot om naar het hoofdmenu te gaan.
					</p>
					<p>
						<BearPawButton onClick={() => history.push("/home")} />
					</p>
				</Box>
			</CenterContainer>
		);
	}

	if (bear === null) throw new Error(`Should never happen.`);

	return (
		<Box>
			<div>Je hebt {bear.name} gevangen!</div>
			<div>Klik op de berenpoot om naar je verzameling te gaan.</div>
			<div><BearPawButton onClick={() => history.push("/collection")} /></div>
		</Box>
	)
};

import React, { useEffect, useState } from "react"
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { createRequiredContext } from "../util";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { AuthInfo } from "../database";
import { BearButton } from "./BearButton";
import { LoadSpinner } from "./LoadSpinner";
import { CenterContainer } from "./CenterContainer";
import { Box } from "./Box";


export interface IAuthProviderProps {
  showLogout?: boolean;
}


export const AuthContext = createRequiredContext<AuthInfo>();

export const AuthProvider: React.FC<IAuthProviderProps> = (props) => {

  const [loaded, setLoaded] = useState(false);
  const [authInfo, setAuthInfo] = useState<AuthInfo | null>(null);

  const showLogout = (props.showLogout !== undefined) ? props.showLogout : true;


  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'redirect',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/signedIn',

    // We will display Google and Facebook as auth providers.
    signInOptions: [
      app.auth.FacebookAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false
    }
  };

  useEffect(() => {
    let mounted = true;

    const unregisterAuthObserver = app.auth().onAuthStateChanged((user) => {
      console.log("STATE CHANGE");
      console.log(user);

      const newLoaded = true;
      const newSignedIn = !!user;

      if (!mounted) return;
      if ((authInfo !== null) === newSignedIn && loaded === newLoaded) return;

      
      if (user) {
        setAuthInfo({
          id: user.uid,
          name: user.displayName || "Onbekend",
        });
      }
      else {
        setAuthInfo(null);
      }
      setLoaded(true);
    });

    return () => {
      mounted = false;
      unregisterAuthObserver();
    }
  }, [authInfo, loaded]);

  if (!loaded) {
    return <LoadSpinner />;
  }

  if (authInfo === null) {
    return (
      <CenterContainer>
        <Box>
          <p>Vanwege veiligheidsredenen vragen we je om eerst in te loggen met je Facebook-account. Je deelt geen gegevens met ons door Facebook-login te gebruiken.</p>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={app.auth()}/>
        </Box>
      </CenterContainer>
    );
  }

  if (!showLogout) {
    return (
      <AuthContext.Provider value={authInfo}>
        <div>
          {props.children}
        </div>
      </AuthContext.Provider>
    );
  }

  return (
    <CenterContainer>
      <AuthContext.Provider value={authInfo}>
        <div>
          {props.children}
        </div>
        <Box>
          <p>Je bent ingelogd met Facebook.</p>
          <p><BearButton onClick={() => app.auth().signOut()} color="secondary">Log uit</BearButton></p>
        </Box>
      </AuthContext.Provider>
    </CenterContainer>
  );
};

import React, { useContext } from "react"
import { BackgroundClassNameContext } from "./BackgroundProvider";
import { FacebookShareCount, FacebookShareButton, FacebookIcon, TwitterIcon, TwitterShareButton } from "react-share";

export const Credits: React.FC = (props) => {

	const backgroundClassName = useContext(BackgroundClassNameContext);

	return (
		<div className={"credits " + backgroundClassName}>
			<div style={{margin: 5}}>
				<FacebookShareButton  url={"https://www.berenjacht.org"}> <FacebookIcon size={32} round /></FacebookShareButton>
				<TwitterShareButton  url={"https://www.berenjacht.org"}> <TwitterIcon size={32} round /></TwitterShareButton>
			</div>
			
			<div>Berenjacht is een initiatief van <a href="mailto:Karel.Crombecq@gmail.com">Karel Crombecq</a> (<a href="https://twitter.com/KarelCrombecq" target="_blank">Twitter</a>)</div>
			<div>Art door <a href="https://www.poodlesoup.be/">Poodle Soup</a></div>
		</div>
	);
}
import React, {  } from "react"

export interface IAgeSubtextProps {
	registerTime: number;
}


export const AgeSubtext: React.FC<IAgeSubtextProps> = (props) => {

	const currTime = Date.now();
	const registerTime = props.registerTime;
	const ageInSeconds = (currTime - registerTime) / 1000;
	let ageInDays = Math.ceil(ageInSeconds / 60 / 60 / 24);
	if (ageInDays < 1) ageInDays = 1;

	const dayString = (ageInDays === 1) ? "dag" : "dagen";


	return (
		<div className="bear-subtitle">
			<div className="bear-subtitle-item">{ageInDays} {dayString} oud</div>
		</div>
	);
};


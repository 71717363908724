
export enum CatchBearError {
	BearDeleted,
	AlreadyCaught,
}

export enum GetBearError {
	BearDeleted,
}

export enum RegisterBearError {
	PictureUnavailable,
	RegisteredTooEarly
}

export enum CatchWildBearError {
	PictureUnavailable,
	AlreadyCaught,
}

export enum ClaimWildBearError {
	BearDeleted,
	NoOwnBear,
}
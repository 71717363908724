import React, { } from "react"
import { BearButton } from "./BearButton";
import { useHistory } from "react-router-dom";
import { Credits } from "./Credits";
import { BackgroundProvider } from "./BackgroundProvider";

const TopLeftImage = require("./images/splash-illustration-top-left.png");
const TopRightImage = require("./images/splash-illustration-top-right.png");
const BottomLeftImage = require("./images/splash-illustration-bottom-left.png");
const BottomRightImage = require("./images/splash-illustration-bottom-right.png");
const Logo = require("./images/logo.png");


export const SplashScreen: React.FC = () => {

	const history = useHistory();

	function onNext() {
		history.push("/home");
	}

	return (
		<BackgroundProvider className="splash-background">
		<div className="splash">
			<div className="content splash-content">
				<div className="splash-illustration-1"><img src={TopLeftImage} className="splash-illustration-image-1" alt="bear with child"></img></div>
				<div className="splash-illustration-2"><img src={TopRightImage} className="splash-illustration-image-2" alt="bear with child"></img></div>
				<div className="splash-illustration-3"><img src={BottomLeftImage} className="splash-illustration-image-3" alt="bear with child"></img></div>
				<div className="splash-illustration-4"><img src={BottomRightImage} className="splash-illustration-image-4" alt="bear with child"></img></div>
				<div className="splash-title-container">
					<div className="splash-title">
						<img src={Logo} className="splash-title-img" alt="logo"></img>
					</div>
					<div className="splash-button">
					<BearButton onClick={onNext}>Start</BearButton>
				</div>
				</div>
			</div>
			<Credits />
		</div>
		</BackgroundProvider>
	)
};


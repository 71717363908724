import React, { } from "react"
import { LoginArea } from "./LoginArea";
import { BackgroundProvider } from "./BackgroundProvider";
import { Credits } from "./Credits";
import { CenterContainer } from "./CenterContainer";
import { BearCollection } from "./BearCollection";


export const CollectionMenu: React.FC = () => {

	return (
		<BackgroundProvider className="home-background">
			<CenterContainer>
				<LoginArea centered={false}>
					<BearCollection />
				</LoginArea>
				<Credits />
			</CenterContainer>
		</BackgroundProvider>
	);
};

import { UserInfo } from './UserInfo';
import { Backend } from './Backend';
import { UserDataDTO, OwnedBearsListDTO, CollectionDTO, GetBearsInVincinityInput, CaughtScanDTO } from 'shared';


export class User {

	private backend: Backend;
	info: UserInfo;


	constructor(backend: Backend, userInfo: UserInfo) {
		this.backend = backend;
		this.info = userInfo;
	}

	async getData(): Promise<UserDataDTO> {
		return this.backend.getUserData({});
	}

	async getCollection(offset: number, count: number): Promise<CollectionDTO> {
		return this.backend.getCollection({offset, count});
	}

	async getOwnedBears(): Promise<OwnedBearsListDTO> {
		return this.backend.getOwnedBears({});
	}

	async getCaughtBearsInVincinity(data: GetBearsInVincinityInput): Promise<CaughtScanDTO> {
		return this.backend.getCaughtBearsInVincinity(data);
	}
}
import { AuthInfo } from './AuthInfo';
import { Backend } from './Backend';
import { BearDTO, GPSLocation, GeohashData, CaughtWildBearDTO, RegisteredBearDTO, TryCatchWildBearDTO, CatchExistingBearInput } from 'shared';
import { getFineGeohashes } from '../util';
import { User } from './User';


export class Database {

	private app: firebase.app.App;
	backend: Backend;

	constructor(app: firebase.app.App) {
		this.app = app;
		this.backend = new Backend(this.app);
	}

	async loadUserFromFacebook(authInfo: AuthInfo): Promise<User> {

		const authData = {
			name: authInfo.name,
		};
		console.log("Auth data:");
		console.log(authData);

		const userInfo = await this.backend.getUserFromFacebookAuth(authData);
		return new User(this.backend, userInfo);
	}

	async registerBear(pictureFile: File, bear: BearDTO): Promise<RegisteredBearDTO> {

		// first, upload the picture
		const uuid = await this.backend.uploadPicture(pictureFile);
		console.log("Uploaded to file " + uuid);

		// collect the geohash boxes for this bear catch
		const bboxes = getFineGeohashes(bear.location, GeohashData.fineSearchRadius);

		// now register the bear
		return await this.backend.registerBearWithSuggestions({
			bear: bear,
			pictureUuid: uuid,
			claimingFineGeohashes: bboxes,
		});
	}

	async claimWildBear(wildBearId: string): Promise<void> {
		return this.backend.claimWildBear({wildBearId: wildBearId});
	}

	async catchWildBear(pictureFile: File, location: GPSLocation, forceNewWildBear: boolean = false): Promise<CaughtWildBearDTO> {

		// first, upload the picture
		const uuid = await this.backend.uploadPicture(pictureFile);
		console.log("Uploaded to file " + uuid);

		// collect the geohash boxes for this bear catch
		let bboxes: string[] = [];
		if (!forceNewWildBear) bboxes = getFineGeohashes(location, GeohashData.fineSearchRadius);

		// now register the bear
		return this.backend.catchWildBear({
			pictureUuid: uuid,
			position: location,
			fineGeohashes: bboxes,
		});
	}

	async tryCatchWildBear(pictureFile: File, location: GPSLocation, forceNewWildBear: boolean = false): Promise<TryCatchWildBearDTO> {
		
		// first, upload the picture
		const uuid = await this.backend.uploadPicture(pictureFile);
		console.log("Uploaded to file " + uuid);

		// collect the geohash boxes for this bear catch
		let bboxes: string[] = [];
		if (!forceNewWildBear) bboxes = getFineGeohashes(location, GeohashData.fineSearchRadius * 4);

		// now register the bear
		return this.backend.tryCatchWildBear({
			pictureUuid: uuid,
			position: location,
			fineGeohashes: bboxes,
		});
	}

	async catchExistingBear(input: CatchExistingBearInput): Promise<void> {
		return this.backend.catchExistingBear(input);
	}

	async deleteBear() {
		await this.backend.deleteBear();
	}

	async getPictureUrl(pictureUuid: string): Promise<string> {
		return this.backend.getPictureUrl(pictureUuid);
	}
}


export const BearValidation = {

	//namePattern: /^[\p{L}. -]*$/u,
	namePattern: /^[ÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜäëïöü¡¿çÇßØøÅåÆæÞþÐð""\w\d\s-'.,&amp;#@:?!()$/]+$/,

	minNameLength: 2,
	maxNameLength: 24,
	
	minAnswerLength: 2,
	maxAnswerLength: 32,

	isValidName(fullName: string, min: number, max: number) {
		let name = fullName.trim();
		if (name.length < min) return false;
		if (name.length > max) return false;
		if (!BearValidation.namePattern.test(name)) return false;
		return true;
	}
}
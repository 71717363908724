import React, { useCallback } from "react"
import { useParams, useHistory } from "react-router-dom";
import { DatabaseContext } from "./DatabaseContext";
import { useRequiredContext, usePromise } from "../util";
import { GetBearError } from "shared";
import { CenterContainer } from "./CenterContainer";
import { Box } from "./Box";
import { BearButton } from "./BearButton";
import { LoadSpinner } from "./LoadSpinner";
import { BearCard } from "./BearCard";
import { PopupContext } from "./PopupContext";
import { Credits } from "./Credits";
import { Localization } from "../localization";


export const FindBearMenu: React.FC = () => {

	const history = useHistory();

	const database = useRequiredContext(DatabaseContext);
	const popup = useRequiredContext(PopupContext);

	const { bearId } = useParams();

	const loadBear = useCallback(async () => {
		if (bearId === undefined) throw new Error(`Dit is geen geldige berenjacht URL.`);

		try {
			const bear = await database.backend.getBear({ bearId: bearId });
			return bear;
		}
		catch (err) {
			if (err.code === GetBearError.BearDeleted) throw new Error(`Helaas is deze beer ondertussen verwijderd door de eigenaar. Je kunt hem niet meer vangen!`);
		}

		throw new Error(`Should never happen.`);
	}, [bearId, database]);

	const [bear, bearError, loadingBear] = usePromise(loadBear, null);

	const catchBear = useCallback(async () => {
		history.push("/catch/" + bearId);
	}, [history, bearId]);

	const explain = useCallback(() => {
		popup.show(Localization.explainTitle, Localization.explainText);
	}, [popup]);

	if (loadingBear) {
		return <LoadSpinner />;
	}

	if (bearError !== null) {
		return (
			<CenterContainer>
				<Box>
					<p>
						{bearError.message}
					</p>
					<BearButton color="primary" onClick={() => history.push("/")}>Bezoek Website</BearButton>
				</Box>
			</CenterContainer>
		);
	}

	if (bear === null) throw new Error(`Should never happen.`);

	return (
		<CenterContainer>
			<Box>
				<div>
					Je hebt een beer gevonden!
			</div>
				<div>
					<BearButton color="secondary" onClick={catchBear}>Vang Beer</BearButton>&nbsp;&nbsp;
					<BearButton color="primary" onClick={explain}>Wat is berenjacht?</BearButton>
				</div>
				<BearCard bear={bear} />
			</Box>
			<Credits />
		</CenterContainer>
	);
};

import React, { useEffect, useRef, useCallback, } from "react"


export interface IQrCanvasProps {
	qrImageUrl: string;
}

export const QrCanvas: React.FC<IQrCanvasProps> = (props) => {

	const { qrImageUrl } = props;

	const ref = useRef<HTMLCanvasElement>(null);

	const renderToCanvas = useCallback(async (canvas: HTMLCanvasElement) => {
		console.log("Got canvas!");
		const context = canvas.getContext("2d");

		const background = await loadImage("./images/qr-page.jpg");
		const qr = await loadImage(qrImageUrl);
		
		context?.drawImage(background, 0, 0);
		context?.drawImage(qr, 290, 560);

	}, [qrImageUrl]);

	useEffect(() => {
		if (ref.current !== null) {
			renderToCanvas(ref.current);
		}
	}, [renderToCanvas]);

	return (
		<canvas ref={ref} width={744} height={1052}/>
	  );
};

async function loadImage(url: string): Promise<HTMLImageElement> {
	const promise = new Promise<HTMLImageElement>((resolve, reject) => {
		const img = new Image();
		img.onload = () => {
			resolve(img);
		}
		img.onerror = () => {
			reject(new Error("Failed to download image."));
		}
		img.src = url;
	});
	return promise;
}
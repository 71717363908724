import React, { useCallback, ReactNode, useState } from "react"
import { BearCard } from "./BearCard";
import { Box } from "./Box";
import { Header } from "./Header";
import { useRequiredContext, useView } from "../util";
import { CollectionDTO } from "shared";
import { UserContext } from "./UserProvider";
import { DataView } from "./DataView";
import { CaughtWildBearCard } from "./WildBearCard";
import { BearButton } from "./BearButton";
import { useHistory } from "react-router-dom";

type BearView = {
	view: ReactNode;
	time: number;
}

interface IBearCollectionProps {
}


export const BearCollection: React.FC<IBearCollectionProps> = (props) => {

	const history = useHistory();
	const user = useRequiredContext(UserContext);

	const loadCollection = useCallback(async () => {
		return await user.getCollection(0, 1000);
	}, [user]);

	const countStep = 10;
	const [count, setCount] = useState(countStep);

	const [collection, error, loading] = useView<CollectionDTO>(loadCollection);

	let hasMore = false;
	if (collection) {
		hasMore = count < collection.caughtBears.length + collection.caughtWildBears.length;
	}

	const getBearList = useCallback(() => {

		let bearViews: BearView[] = [];

		if (collection) {
			for (const caughtBear of collection.caughtBears) {
				
				const time = caughtBear.time;
				const dateString = new Intl.DateTimeFormat("nl-BE").format(time);

				bearViews.push({
					view: (
						<BearCard bear={caughtBear.bear}>
								<div className="bear-subtitle-item" style={{ flexGrow: 1 }}>Gevangen op {dateString}</div>
							</BearCard>
					),
					time: caughtBear.time,
				});
			}

			for (const caughtWildBear of collection.caughtWildBears) {
				bearViews.push({
					view: (<CaughtWildBearCard bear={caughtWildBear} />),
					time: caughtWildBear.time,
				});
			}
		}

		bearViews.sort((a, b) => b.time - a.time);

		// filter!
		bearViews = bearViews.slice(0, count);


		if (bearViews.length === 0) {
			return <Box>
				<div>Helaas heb je nog geen beren gevangen. Zoek beren op straat en scan de QR-code om ze te verzamelen! Als je een beer vindt zonder QR-code, kun je hem ook vangen met de knop hieronder.</div>
				<div style={{height: 20}}/>
				<div><BearButton onClick={() => history.push("/catchWildBear")}>Vang Wilde Beer</BearButton></div>
				</Box>;
		}
		else {
			return bearViews.map((bear, idx) => {

				return (
					<div className="bear-collection-item" key={idx}>
						<Box>
							{bear.view}
						</Box>
					</div>
				);
			});
		}
	}, [collection, count, history]);

	
	return (
		<DataView loading={loading} error={error}>
			<Header title="Jouw Verzameling" />
			<div className="bear-collection">
				{getBearList()}
			</div>
			<div className="paginate">
				{hasMore && <BearButton color="primary" onClick={() => setCount(count+countStep)}>Meer laden...</BearButton>}
			</div>
		</DataView>
	);
};


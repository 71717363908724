import { GPSLocation } from "./GPSLocation";

export * from "./GPSLocation"


export interface BearDTO {
	id: string;
	name: string;
	pictureUrl: string;
	questionIndex: number;
	questionAnswer: string;
	registerTime: number;
	location: GPSLocation;
}

export interface RegisteredBearDTO {
	claimSuggestions: WildBearSuggestion[];
}

export interface CaughtBearDTO {
	time: number;
	bear: BearDTO;
}

export interface OwnedBearDTO {
	timesCaught: number;
	bear: BearDTO;
}

export interface OwnedBearsListDTO {
	ownedBears: OwnedBearDTO[];
}

export interface UserDataDTO {
	ownedBears: OwnedBearDTO[];
	caughtBears: CaughtBearDTO[];
}

export interface CollectionDTO {
	caughtBears: CaughtBearDTO[];
	caughtWildBears: CaughtWildBearDTO[];
}

export interface UserInfoDTO {
	id: string;
	name: string;
}

export interface BearScanDTO {
	bears: BearDTO[];
	wildBears: WildBearDTO[],
	geoHashes: string[];
}

export interface CaughtWildBearScanDTO {
	caught: boolean;
	wildBear: WildBearDTO;
}

export interface CaughtBearScanDTO {
	caught: boolean;
	bear: BearDTO;
}

export interface CaughtScanDTO {
	bears: CaughtBearScanDTO[];
	wildBears: CaughtWildBearScanDTO[];
	geoHashes: string[];
}

export type OwnedBearSuggestion = {
	bear: BearDTO;
	distance: number;
}

export type WildBearSuggestion = {
	bear: WildBearDTO;
	distance: number;
}

export interface TryCatchWildBearDTO {
	suggestionsFound: boolean;
	caughtBear?: CaughtWildBearDTO;
	ownedBears?: OwnedBearSuggestion[];
	wildBears?: WildBearSuggestion[];
}

export interface CaughtWildBearDTO {
	id: string;
	first: boolean;
	time: number;
	pictureUuid: string;
}

export interface WildBearDTO {
	id: string;
	location: GPSLocation;
	pictureUuid: string;
	time: number;
}

const GeohashData = {
	normalPrecision: 5,
	normalSearchRadius: 1.5,
	finePrecision: 8,
	fineSearchRadius: 0.005
}
export { GeohashData };
import React, { } from "react"
import { BearMapLoader } from "./BearMapLoader";
import { OptionalAuthProvider } from "./OptionalAuthProvider";
import { OptionalUserProvider } from "./OptionalUserProvider";


export const BearMapMenu: React.FC = () => {

	return (
	<OptionalAuthProvider>
		<OptionalUserProvider>
			<BearMapLoader />
		</OptionalUserProvider>
	</OptionalAuthProvider>
	);
};

import React, { } from "react"
import { LoginArea } from "./LoginArea";
import { BackgroundProvider } from "./BackgroundProvider";
import { UserDataArea } from "./UserDataArea";
import { Credits } from "./Credits";
import { CenterContainer } from "./CenterContainer";
import { RegisterBear } from "./RegisterBear";


export const ProfileMenu: React.FC = () => {
	return (
		<BackgroundProvider className="home-background">
			<CenterContainer>
				<LoginArea centered={false}>
					<UserDataArea>
						<RegisterBear />
					</UserDataArea>
				</LoginArea>
				<Credits />
			</CenterContainer>
		</BackgroundProvider>
	);
};

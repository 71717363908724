import React, { useCallback } from "react"
import { useHistory } from "react-router-dom";
import { BearFinder } from "./BearFinder";
import { BearPawButton } from "./BearPawButton";
import { Credits } from "./Credits";
import { PopupContext } from "./PopupContext";
import { useRequiredContext } from "../util";
import { Localization } from "../localization";
import { BearButton } from "./BearButton";

const HomeLogo = require("./images/home-logo.png");

export const Home: React.FC = () => {
	
	const popup = useRequiredContext(PopupContext);
	const history = useHistory();
	
	const explain = useCallback(() => {
		popup.show(Localization.explainTitle, Localization.explainText);
	}, [popup]);

	return (
		<div className="home home-background">
			<div className="content">
				<img style={{width: "100%"}} src={HomeLogo} alt="home logo"></img>

				<BearButton color="primary" bearColored={true} onClick={explain}>Wat is berenjacht?</BearButton>

				<div className="home-header">Wil jij beren verzamelen?</div>
				<div className="home-text">
					<p>Zoek een beertje in je buurt! Scan de QR-code en voeg de beer toe aan je verzameling.</p>
					<p><BearPawButton onClick={() => history.push("/collection")} text="Verzameling" /></p>
				</div>

				<div className="home-header">Een beer zonder QR-code gevonden?</div>
				<div className="home-text">
					Je kunt deze beer toch vangen! Neem er een foto van.
					<p><BearPawButton onClick={() => history.push("/catchWildBear")} text="Vang Wilde Beer" /></p>
				</div>


				<div className="home-header">Heb jij een beer voor je raam gezet?</div>
				<div className="home-text">
					<p>Ga naar je profiel om je beer te registreren.</p>
					<p><BearPawButton onClick={() => history.push("/user")} text="Profiel" /></p>
				</div>

				<div className="home-header">Beren in je buurt</div>
				<div className="home-text">
					<p>Zoek beren op de kaart om te vangen.</p>
					<p><BearPawButton onClick={() => history.push("/map")} text="Berenkaart" /></p>
				</div>
				<Credits />
			</div>
		</div>
	);
};

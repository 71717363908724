import React from "react"
import { useForm } from 'react-hook-form';
import { TextField } from "@material-ui/core";
import { QuestionList } from "shared";
import { BearButton } from "./BearButton";
import { BearValidation } from "shared/src/dto/Validation";

export interface IBearData {
	questionIndex: number;
	questionAnswer: string;
	bearName: string;
}

export interface IBearDataFormProps {
	onSubmit: (data: IBearData) => void;
	questionIndex: number;
}

export const BearDataForm: React.FC<IBearDataFormProps> = (props) => {

	const { register, handleSubmit, errors } = useForm();

	const onSubmit = (data: any) => {
		props.onSubmit({
			bearName: data.name,
			questionAnswer: data.answer,
			questionIndex: props.questionIndex,
		});
	};

	function getErrorText(err: any, min: number, max: number): string | null {
		let errorText = null;
		if (!!err) {
			errorText = "";
			if (err.type === "required") errorText += "Je moet een antwoord geven.";
			else {
				if (err.type === "minLength" || err.type === "maxLength") errorText += "Het antwoord moet uit " + min + "-" + max + " karakters bestaan. ";
				if (err.type === "pattern") errorText += "De naam voor je beer mag enkel letters en spaties bevatten. ";
			}
		}
		return errorText;
	}

	let nameHelperText = getErrorText(errors.name, BearValidation.minNameLength, BearValidation.maxNameLength);
	let answerHelperText = getErrorText(errors.answer, BearValidation.minAnswerLength, BearValidation.maxAnswerLength);

	const question = QuestionList[props.questionIndex].formQuestion;

	function getLengthValidation(min: number, max: number) {
		return {
			minLength: (value: string) => value.trim().length >= min,
			maxLength: (value: string) => value.trim().length <= max,
		};
	}

	return (
		<div>
			<form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

				<div>
					Wat is de naam van je beer?
					<div className="bear-input-container">
						<TextField
							className="bear-input"
							fullWidth
							name="name"
							label=""
							error={!!errors.name}
							helperText={errors.name && nameHelperText}
							inputRef={register({
								required: true,
								validate: getLengthValidation(BearValidation.minNameLength, BearValidation.maxNameLength),
								pattern: BearValidation.namePattern
							})}
						/>
					</div>
				</div>

				<div>
					{question}
					<div className="bear-input-container">
						<TextField
							className="bear-input"
							fullWidth
							name="answer"
							label=""
							error={!!errors.answer}
							helperText={errors.answer && answerHelperText}
							inputProps={{autoCapitalize: "off"}}
							inputRef={register({
								required: true,
								validate: getLengthValidation(BearValidation.minAnswerLength, BearValidation.maxAnswerLength),
								pattern: BearValidation.namePattern
							})}
						/>
					</div>
				</div>
				<div>
					<BearButton type="submit" color="secondary">Volgende</BearButton>
				</div>
			</form>
		</div>
	)
};


import React, { } from "react"
import { AuthProvider } from "./AuthProvider";
import { UserProvider } from "./UserProvider";
import { CenterContainer } from "./CenterContainer";

export interface ILoginAreaProps {
	showLogout?: boolean;
	centered?: boolean;
	backgroundColor?: string;
}

export const LoginArea: React.FC<ILoginAreaProps> = (props) => {

	const centered = (props.centered !== undefined) ? props.centered : true;

	console.log("Login area!");
	return (
		<CenterContainer backgroundColor={props.backgroundColor} center={centered}>
			<AuthProvider showLogout={props.showLogout}>
				<UserProvider>
					{props.children}
				</UserProvider>
			</AuthProvider>
		</CenterContainer>
	);
};


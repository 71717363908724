import React, { useEffect } from "react"


export type ViewFunction<T> = () => Promise<T>;

export type ViewResult<T> = {
	value: T | null;
	error: any | null;
	loading: boolean;
}

export function useView<T>(promiseFunction: ViewFunction<T>): [T|null, Error, boolean] {
	const [state, setState] = React.useState<ViewResult<T>>({ value: null, error: null, loading: true })

	useEffect(() => {

		let isSubscribed = true;

		const promise = promiseFunction();
		promise
			.then(value => isSubscribed ? setState({ value, error: null, loading: false }) : null)
			.catch(error => isSubscribed ? setState({ value: null, error: error, loading: false }) : null)

		return () => {
			console.log("CANCEL???");
			isSubscribed = false;
		};
	}, [promiseFunction]);

	const { value, error, loading } = state
	return [value, error, loading];
}
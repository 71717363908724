import React, { } from "react"

export interface IBoxProps {
  color?: string;
}

export const Box: React.FC<IBoxProps> = (props) => {

  const color = props.color !== undefined ? props.color : "primary";

  return (
    <div className={"box box-" + color}>{props.children}</div>
  )
};

import React from "react"

export interface ITransformData {
	rotation: number;
	scale: [number, number];
}

export interface IImagePreviewProps {
	src: string | undefined | null,
	transform?: string,
	transformOrigin?: string;
}


export const ImagePreview: React.FC<IImagePreviewProps> = (props) => {

	if (props.src === null || props.src === undefined) {
		return null;
	}

	return (
		<img src={props.src} style={{width: "95%", transform: props.transform, transformOrigin: props.transformOrigin}} alt="Bear preview"></img>
	);
};



export default {
	explainTitle: "Wat is berenjacht?",
	explainText: "Berenjacht is een initiatief om deze barre Coronatijden op te vrolijken. Iedereen kan een beer voor het raam zetten en registreren op deze website. Jij kan dan deze beer vangen en aan je verzameling toevoegen door de bijhorende QR-code te scannen of gewoon een foto te trekken!",

	registerFormUploadQuestion: "Maak een foto van je beer voor het raam.",

	catchWildBearUploadQuestion: "Maak een foto van de beer voor het raam.",

	registeredBearTitle: "Beer Geregistreerd!",
	registeredBearText: "Je beer is geregistreerd. Je kunt nu een QR-code afprinten en hem boven je raam hangen.",

	registeredAndClaimedBearTitle: "Beer Geregistreerd!",
	registeredAndClaimedBearText: "Je beer is geregistreerd. Je kunt nu een QR-code afprinten en hem boven je raam hangen.",

	claimedBearTitle: "Beer Geregistreerd!",

	caughtRealWildBearText: "Je hebt een wilde beer gevangen!",
	caughtOwnedWildBearText: "Je hebt een beer gevangen!",

};
import React, { useCallback, ReactNode } from "react"
import { BearCard } from "./BearCard";
import { Box } from "./Box";
import { OwnedBearSuggestion, WildBearSuggestion } from "shared";
import { WildBearCard } from "./WildBearCard";
import { BearButton } from "./BearButton";

export type BearList = {
	bears: OwnedBearSuggestion[];
	wildBears: WildBearSuggestion[];
}

type BearView = {
	view: ReactNode;
	distance: number;
}

interface IExistingBearListProps {
	list: BearList,
	onPick: (wild: boolean, bearId: string) => void;
	onNone: () => void;
	pickText: string;
	noneText: string;
}


export const ExistingBearList: React.FC<IExistingBearListProps> = (props) => {

	const { list, onPick, onNone, pickText, noneText } = props;

	const getBearList = useCallback(() => {

		let bearViews: BearView[] = [];

		for (const bear of list.bears) {
			
			bearViews.push({
				view: (
					<BearCard bear={bear.bear}>
						<BearButton onClick={() => onPick(false, bear.bear.id)}>{pickText}</BearButton>
						</BearCard>
				),
				distance: bear.distance,
			});
		}

		for (const wildBear of list.wildBears) {
			bearViews.push({
				view: (<WildBearCard bear={wildBear.bear}>
						<BearButton onClick={() => onPick(true, wildBear.bear.id)}>{pickText}</BearButton>
					</WildBearCard>),
				distance: wildBear.distance,
			});
		}

		bearViews.sort((a, b) => a.distance - b.distance);

		if (bearViews.length === 0) {
			return <Box>Helaas heb je nog geen beren gevangen. Zoek beren op straat en scan de QR-code om ze te verzamelen!</Box>;
		}
		else {
			return bearViews.map((bear, idx) => {

				return (
					<div className="bear-collection-item" key={idx}>
						<Box>
							{bear.view}
						</Box>
					</div>
				);
			});
		}
	}, [list, onPick, pickText]);

	
	return (
		<div>
			{getBearList()}
			<Box>
				<BearButton color="primary" onClick={onNone}>{noneText}</BearButton>
			</Box>
		</div>
	);
};


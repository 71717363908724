import React, { } from "react"
import { CircularProgress } from "@material-ui/core";
import { CenterContainer } from "./CenterContainer";
import { Box } from "./Box";


export const LoadSpinner: React.FC = (props) => {
  return (
    <CenterContainer>
      <Box>
      <p>Even geduld...</p>
        <CircularProgress color="secondary"></CircularProgress>
      </Box>
    </CenterContainer>
  )
};

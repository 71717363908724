import React, { useState, useCallback } from "react"
import Button from "@material-ui/core/Button";
import { Dialog, DialogContentText, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { IPopup, PopupContext } from "./PopupContext";

export interface IPopupProps {
}

export const PopupProvider: React.FC<IPopupProps> = (props) => {

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const popup: IPopup = {
    show: (title: string, text: string) => {
      setTitle(title);
      setText(text);
      setOpen(true);
    }
  };

	return (
		<div style={{height: "100%", minHeight: "100%"}}>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <PopupContext.Provider value={popup}>
        {props.children}
      </PopupContext.Provider>
    </div>
	);
};


import React from "react";
import { BearButton } from "./BearButton";
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { IconButton } from "@material-ui/core";
import RefreshIcon from '@material-ui/icons/Refresh';

export interface BearMapHeaderProps {
	onBack(): void;
	onRefresh(): void;
	showTutorialText: boolean;
}

export const BearMapHeader: React.FC<BearMapHeaderProps> = (props) => {
	return (
		<div className="map-header">
			<div className="map-header-back">
				<BearButton noButton={true} color="secondary" style={{padding: 0, minWidth: 0, marginLeft: 10}}><IconButton style={{padding: 0}} onClick={props.onBack} color="inherit"><ArrowBackIcon fontSize="large" /></IconButton></BearButton>
			</div>
			<div className="map-header-text">
				{props.showTutorialText && <span>Klik op een beer!</span>}
			</div>
		</div>
	);
};

import React from "react"
import { Box } from "./Box";
import { LoadSpinner } from "./LoadSpinner";
import { CenterContainer } from "./CenterContainer";
import { useHistory } from "react-router-dom";
import { BearPawButton } from "./BearPawButton";

export interface IDataViewProps {
	loading: boolean;
	error?: Error | null;

}

export const DataView: React.FC<IDataViewProps> = (props) => {

	const history = useHistory();

	if (props.loading) {
		return <LoadSpinner />;
	}

	if (props.error !== undefined && props.error !== null) {
		return (
			<CenterContainer>
				<Box>
					<p>
						{props.error.message}
					</p>
					<p>
						Klik op de berenpoot om naar het hoofdmenu te gaan.
					</p>
					<p>
						<BearPawButton onClick={() => history.push("/home")} />
					</p>
				</Box>
			</CenterContainer>
		);
	}

	return <span>{props.children}</span>;
};

import React, { } from "react"
import { LoginArea } from "./LoginArea";
import { BackgroundProvider } from "./BackgroundProvider";
import { Credits } from "./Credits";
import { CenterContainer } from "./CenterContainer";
import { CatchWildBear } from "./CatchWildBear";
import { Header } from "./Header";


export const CatchWildBearMenu: React.FC = () => {
	return (
		<BackgroundProvider className="home-background">
			<CenterContainer>
				<LoginArea centered={false}>
					<Header title="Vang Wilde Beer" />
					<CatchWildBear />
					<div style={{height: 20}}/>
				</LoginArea>
				<Credits />
			</CenterContainer>
		</BackgroundProvider>
	);
};

import React, { useCallback, useState, } from "react"
import { Dialog, DialogContentText, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useRequiredContext } from "../util";
import { DatabaseContext } from "./DatabaseContext";
import { BearCard } from "./BearCard";
import { RegisterBearForm } from "./RegisterBearForm";
import { OwnedBearDTO } from "shared";
import { BearButton } from "./BearButton";
import { useHistory } from "react-router-dom";
import { LoadSpinner } from "./LoadSpinner";
import { Box } from "./Box";
import { UserDataContext } from "./UserDataArea";
import { Header } from "./Header";

export const RegisterBear: React.FC = (props) => {

	const { userData, onChange } = useRequiredContext(UserDataContext);

	let bear: OwnedBearDTO | null = null;
	if (userData.ownedBears.length > 0) bear = userData.ownedBears[0];

	const history = useHistory();

	const database = useRequiredContext(DatabaseContext);

	const [deleting, setDeleting] = useState(false);
	const [confirmingDelete, setConfirmingDelete] = useState(false);

	const onRegistered = useCallback(() => {
		console.log("Registered!");
		onChange();
	}, [onChange]);

	const deleteBear = useCallback(async () => {
		setConfirmingDelete(true);
	}, [setConfirmingDelete]);

	const handleClose = useCallback(async (confirmed: boolean) => {
		setConfirmingDelete(false);
		
		if (confirmed) {
			setDeleting(true);
			await database.deleteBear();
			console.log("Deleted!");
			onChange();
		}
		
	}, [onChange, setDeleting, database, setConfirmingDelete]);

	const goToQR = useCallback(() => {
		history.push("/qr");
	}, [history]);

	const getBearState = useCallback(() => {
		if (bear === null) {
			return (
				<div>
					<RegisterBearForm onRegistered={onRegistered} />
				</div>
			);
		}
		else {
			return (
				<Box>
					<p>Print de QR-code af en plak hem boven jouw beer. Zo kunnen spelers je beer vangen!</p>
					<p><BearButton color="secondary" onClick={goToQR}>Print QR-code</BearButton></p>
					<BearCard bear={bear.bear}>
						<div className="bear-subtitle-item" style={{ flexGrow: 1 }}>{bear.timesCaught} keer gevangen</div>
					</BearCard>
					<BearButton color="secondary" onClick={deleteBear}>Verwijder Beer</BearButton>
					<Dialog
						open={confirmingDelete}
						onClose={handleClose}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">Verwijder Beer</DialogTitle>
						<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Ben je zeker dat je je beer wilt verwijderen?
						</DialogContentText>
						</DialogContent>
						<DialogActions>
						<Button onClick={() => handleClose(false)} color="primary">
							Nee Bedankt
						</Button>
						<Button onClick={() => handleClose(true)} color="secondary" autoFocus>
							Verwijder Beer
						</Button>
						</DialogActions>
					</Dialog>
				</Box>
			);
		}
	}, [deleteBear, handleClose, onRegistered, bear, confirmingDelete, goToQR]);


	if (deleting) {
		return <LoadSpinner />;
	}

	return (
		<div>
			<Header title="Profiel" />
			{getBearState()}
		</div>
	  );
};


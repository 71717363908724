import React from "react"
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { DatabaseContext } from "./DatabaseContext";
import { Database } from "../database";

var firebaseConfig = {
    apiKey: "AIzaSyDQujkOp78kVty4TedYJFtK5kvPSNOX-P0",
    authDomain: "berenjacht-d492a.firebaseapp.com",
    databaseURL: "https://berenjacht-d492a.firebaseio.com",
    projectId: "berenjacht-d492a",
    storageBucket: "berenjacht-d492a.appspot.com",
    messagingSenderId: "327432191844",
    appId: "1:327432191844:web:045766fec9e072835fc471",
    measurementId: "G-R1FHP1HVL4"
  };

export const DatabaseProvider: React.FC = (props) => {

  app.initializeApp(firebaseConfig);
  //app.analytics();

  const db = new Database(app.app());

	return (
    <DatabaseContext.Provider value={db}>
      {props.children}
    </DatabaseContext.Provider>
	);
};
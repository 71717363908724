import React, { createContext, useContext } from "react"
import { BackgroundClassNameContext } from "./BackgroundProvider";

export interface ICenterContainerProps {
  backgroundColor?: string;
  center?: boolean;
}


export const CenterContext = createContext<boolean>(false);

export const CenterContainer: React.FC<ICenterContainerProps> = (props) => {

  const backgroundClassName = useContext(BackgroundClassNameContext);
  const centeredBefore = useContext(CenterContext);

  const centered = (props.center !== undefined) ? props.center : true;
  
  if (centeredBefore || !centered) return <span>{props.children}</span>;

  let style = undefined;
  if (props.backgroundColor !== undefined) {
    style = {
      backgroundColor: props.backgroundColor,
    };
  }

  return (
    <CenterContext.Provider value={true}>
      <div className={"home " + backgroundClassName} style={style}>
        <div className="content">
          <div className="container">
            {props.children}
          </div>
        </div>
      </div>
    </CenterContext.Provider>
  );
};

import { UserInfoDTO, UserFacebookAuthInput, UserDataInput, UserDataDTO, 
	RegisterBearInput, BearScanDTO, GetBearsInVincinityInput, GetBearInput, 
	BearDTO, CatchBearInput, CatchWildBearInput, CaughtWildBearDTO, CollectionDTO, 
	OwnedBearsListDTO, GetCollectionInput, RegisteredBearDTO, TryCatchWildBearDTO, CatchExistingBearInput, ClaimBearInput,
	CaughtScanDTO } from "shared";
import 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

require("firebase/functions");
require("firebase/storage");

export class BackendError extends Error {
	code: number;

	constructor(code: number, message: string) {
		super(message);
		this.code = code;
	}
}

type BackendCall<In, Out> = (input: In) => Promise<Out>;

export class Backend {

	private app: firebase.app.App;

	constructor(app: firebase.app.App) {
		this.app = app;
	}

	private async executeCallable<Input, Output, ErrorCode>(name: string, input: Input): Promise<Output> {
		const functions = this.app.functions("europe-west1");
		const authFunction = functions.httpsCallable(name);
		let data: any = {};
		try {
			const response = await authFunction(input);
			data = response.data;
		}
		catch (err) {
			throw new Error("An error occured on the server. Please try again.");
		}

		if (data.ok) {
			return data.data;
		}
		else {
			throw new BackendError(data.code, data.error);
		}
	}

	async getUserFromFacebookAuth(data: UserFacebookAuthInput): Promise<UserInfoDTO> {
		return this.executeCallable("getUserFromFacebookAuth", data);
	}

	async getUserData(data: UserDataInput): Promise<UserDataDTO> {
		return this.executeCallable("getUserData", data);
	}

	async getCollection(data: GetCollectionInput): Promise<CollectionDTO> {
		return this.executeCallable("getCollection", data);
	}

	async getOwnedBears(data: UserDataInput): Promise<OwnedBearsListDTO> {
		return this.executeCallable("getOwnedBears", data);
	}

	async uploadPicture(file: File): Promise<string> {
		const storage = this.app.storage();

		const storageRef = storage.ref();
		const imagesRef = storageRef.child('images');
		const uuid = uuidv4();
		const imageRef = imagesRef.child(uuid + ".jpg");

		await imageRef.put(file);

		return uuid;
	}

	async getPictureUrl(pictureUuid: string): Promise<string> {
		const storage = this.app.storage();

		const storageRef = storage.ref();
		const imagesRef = storageRef.child('images');
		const imageRef = imagesRef.child(pictureUuid + ".jpg");

		return imageRef.getDownloadURL();
	}

	async registerBear(data: RegisterBearInput): Promise<RegisteredBearDTO> {
		return this.executeCallable("registerBear", data);		
	}

	async registerBearWithSuggestions(data: RegisterBearInput): Promise<RegisteredBearDTO> {
		return this.executeCallable("registerBearWithSuggestions", data);		
	}

	async claimWildBear(data: ClaimBearInput): Promise<void> {
		return this.executeCallable("claimWildBear", data);		
	}

	async deleteBear(): Promise<void> {
		return this.executeCallable("deleteBear", {});
	}

	async getBearsInVincinity(data: GetBearsInVincinityInput): Promise<BearScanDTO> {
		return this.executeCallable("getBearsInVincinityFaster", data);
	}

	async getCaughtBearsInVincinity(data: GetBearsInVincinityInput): Promise<CaughtScanDTO> {
		return this.executeCallable("getCaughtBearsInVincinity", data);
	}

	async getBear(data: GetBearInput): Promise<BearDTO> {
		return this.executeCallable("getBear", data);
	}
	
	async catchBear(data: CatchBearInput): Promise<BearDTO> {
		console.log("Catch bear:");
		console.log(data);
		return this.executeCallable("catchBear", data);
	}

	async catchWildBear(data: CatchWildBearInput): Promise<CaughtWildBearDTO> {
		return this.executeCallable("catchWildBear", data);
	}

	async tryCatchWildBear(data: CatchWildBearInput): Promise<TryCatchWildBearDTO> {
		return this.executeCallable("tryCatchWildBear", data);
	}

	async catchExistingBear(data: CatchExistingBearInput): Promise<void> {
		return this.executeCallable("catchExistingBear", data);
	}

}
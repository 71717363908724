import React, { useState, ChangeEvent } from "react"
import { useForm } from 'react-hook-form';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { ImagePreview } from "./ImagePreview";
import { useOptionalState, useRequiredContext } from "../util";
import { GPSLocation } from "shared";
import { BearButton } from "./BearButton";
import { GeoLocationContext } from "./GeoLocationContext";
import * as EXIF from "exif-js";
import LoadImage from "blueimp-load-image";

export interface IPhotoData {
	file: File;
	location: GPSLocation;
	imageUrl: string;
}

export interface IRegisterBearFormProps {
	onSubmit: (data: IPhotoData) => void;
	question: string;
	buttonText: string;
}

type FormData = {
	name: string;
	picture: string;
};

export const RegisterBearUploadForm: React.FC<IRegisterBearFormProps> = (props) => {

	const { handleSubmit } = useForm();
	
	const geoLocation = useRequiredContext(GeoLocationContext);

	const [uploadError, setUploadError] = useState<string | null>(null);

	const [photoData, setPhotoData] = useOptionalState<IPhotoData>();
	const [validated, setValidated] = useState(false);

	const onSubmit = (data: any) => {
		if (photoData !== null) {
			props.onSubmit(photoData);
		}
		else {
			console.error("Should not happen!");
		}
	};

	async function handlePhotoUpload(evt: ChangeEvent<HTMLInputElement>) {
		if (evt.target.files === null) {
			setUploadError("Uploaden van foto mislukt. Probeer opnieuw aub.");
			return; // no file list
		}
		const files = evt.target.files;
		if (files.length < 1) {
			setUploadError("Uploaden van foto mislukt. Probeer opnieuw aub.");
			return; // no file
		}
		const file = files[0];
		console.log(files);

		const reader = new FileReader();
		reader.onload = async function () {

			console.log("onload");

			try {
				console.log(this.result);
				const arrayBuffer = this.result as ArrayBuffer;
				const exif = EXIF.readFromBinaryFile(arrayBuffer);
				console.log(exif);
				let orientation: number = 1;
				if (exif && exif.Orientation !== undefined && exif.Orientation !== 0) {
					orientation = exif.Orientation;
				}
				if (orientation < 0 || orientation > 8) orientation = 1;

				let imageUrl = "";
				const canvas = await getOrientedImageAsync(file, orientation);
				if (canvas instanceof HTMLImageElement) {
					imageUrl = canvas.src;
				}
				else {
					imageUrl = canvas.toDataURL();
				}

				const location = await geoLocation.getLocation();

				// convert the file to an object URL that we can pass to the preview
				setPhotoData({
					file: file,
					location: location,
					imageUrl: imageUrl,
				});
				setUploadError(null);
				setValidated(true);
			}
			catch (err) {
				console.log("GOT AN ERROR!");
				console.log(err);
				setUploadError(err.message);
			}
		};
		reader.readAsArrayBuffer(file);


		//setImageSource(file);
	}

	return (
		<form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

			<div>
				{props.question}
			</div>

			<div>
				<input
					accept="image/*"
					style={{ display: "none" }}
					id="photo-file"
					multiple
					type="file"
					onChange={handlePhotoUpload}
				/>
				<label htmlFor="photo-file">
					<BearButton color="primary" component="span">Upload Foto&nbsp;&nbsp;&nbsp;<PhotoCamera /></BearButton>
				</label>
				<div>{uploadError}</div>
			</div>

			<div>
				<ImagePreview src={photoData?.imageUrl}></ImagePreview>
			</div>

			<div>
				<BearButton type="submit" color="secondary" disabled={!validated}>{props.buttonText}</BearButton>
			</div>
		</form>
	)
};

async function getOrientedImageAsync(file: File, orientation: number): Promise<HTMLImageElement | HTMLCanvasElement> {


	const promise = new Promise<HTMLImageElement | HTMLCanvasElement>((resolve, reject) => {
		LoadImage(file, function(img, data) {
			if (img instanceof Event) {
				reject("Failed to upload image.");
			}
			else {
				resolve(img);
			}
		},
		{
			orientation: orientation,
			maxWidth: 720,
		});
	});

	return promise;
}

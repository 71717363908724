import { useContext, Context } from "react";
import React from "react";

export function useRequiredContext<T>(requiredContext: Context<T|undefined>): T {

    const context = useContext<T|undefined>(requiredContext);
	
	if (context === undefined) throw new Error("Trying to use a required context without a provider.");
	return context;
}

export function createRequiredContext<T>() {
	const context = React.createContext<T|undefined>(undefined);
	return context;
}

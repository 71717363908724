import React, { useState, useEffect, useCallback } from "react"
import { Box } from "./Box";
import { useRequiredContext, useOptionalState, createRequiredContext } from "../util";
import { UserContext } from "./UserProvider";
import { LoadSpinner } from "./LoadSpinner";
import { CenterContainer } from "./CenterContainer";
import { BearButton } from "./BearButton";
import { useHistory } from "react-router-dom";
import { OwnedBearsListDTO } from "shared";


export interface IUserDataContext {
	userData: OwnedBearsListDTO;
	onChange: () => void;
}


export const UserDataContext = createRequiredContext<IUserDataContext>()

export const UserDataArea: React.FC = (props) => {

	const history = useHistory();

	const [loading, setLoading] = useState(true);
	const [userData, setUserData] = useOptionalState<OwnedBearsListDTO>();
	const [error, setError] = useOptionalState<Error>();
	const [refreshState, setRefreshState] = useState(0);

	const user = useRequiredContext(UserContext);

	const reload = useCallback(() => {
		setRefreshState(refreshState+1);
	}, [refreshState, setRefreshState]);

	useEffect(() => {

		let mounted = true;

		const loadUserData = async () => {
			setLoading(true);
			console.log("Load user data...");
			try {
				const userData = await user.getOwnedBears();
				if (mounted) setUserData(userData);
			}
			catch (err) {
				if (mounted) setError(err);
			}
			if (mounted) setLoading(false);
		};

		loadUserData();

		return () => {
			mounted = false;
		};

	}, [user, setLoading, setError, setUserData, refreshState]);

	if (loading || userData === null) {
		return <LoadSpinner />;
	}

	if (error !== null) {
		return (
			<CenterContainer>
				<Box>
					<p>
						{error.message}
					</p>
					<BearButton color="primary" onClick={() => history.push("/")}>Bezoek Website</BearButton>
				</Box>
			</CenterContainer>
		);
	}

	const userDataContext: IUserDataContext = {
		userData: userData,
		onChange: reload,
	};

	return (
		<UserDataContext.Provider value={userDataContext}>
			{props.children}
			{/* <RegisterBear onChange={} bear={bear} />
			<div style={{height: 20}}/>
			<BearCollection bears={userData.caughtBears} />
			<div style={{height: 20}}/> */}
		</UserDataContext.Provider>
	);
};

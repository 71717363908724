import { GPSLocation, GeohashData } from "shared";

const ngeohash = require("ngeohash");


export function getPrecisionGeoHashes(location: GPSLocation, radius: number, precision: number): string[] {
	const boundingBox = location.getBoundingBox(radius);
	let bboxes = ngeohash.bboxes(boundingBox.minPoint.latitude, boundingBox.minPoint.longitude, boundingBox.maxPoint.latitude, boundingBox.maxPoint.longitude, precision);
	return bboxes;
}

export function getFineGeohashes(location: GPSLocation, radius: number) {
	return getPrecisionGeoHashes(location, radius, GeohashData.finePrecision);
}

export function getNormalGeohashes(location: GPSLocation, radius: number) {
	return getPrecisionGeoHashes(location, radius, GeohashData.normalPrecision);
}

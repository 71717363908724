import React, { useEffect } from "react"


export type PromiseFunction<T> = () => Promise<T>;

export type PromiseResult<T> = {
	value: T;
	error: any | null;
	loading: boolean;
}

export function usePromise<T>(promiseFunction: PromiseFunction<T>, defaultValue: T): [T, Error, boolean] {
	const [state, setState] = React.useState<PromiseResult<T>>({ value: defaultValue, error: null, loading: true })

	useEffect(() => {

		let isSubscribed = true;

		const promise = promiseFunction();
		promise
			.then(value => isSubscribed ? setState({ value, error: null, loading: false }) : null)
			.catch(error => isSubscribed ? setState({ value: defaultValue, error: error, loading: false }) : null)

		return () => {
			console.log("CANCEL???");
			isSubscribed = false;
		};
	}, [promiseFunction, defaultValue]);

	const { value, error, loading } = state
	return [value, error, loading];
}
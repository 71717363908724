import { GPSLocation, ServerError } from "shared";
import Cookies from "js-cookie";

export enum GeoLocationError {
	PermissionDenied,
	Other,
}

export class GeoLocation {

	private requestedBefore: boolean;


	constructor() {
		const cookieFound = Cookies.get("GeoLocationAllowed");
		this.requestedBefore = (cookieFound !== undefined);
	}

	isActivated(): boolean {
		if (!this.requestedBefore) return false;
		return true;
	}

	async getLocation(): Promise<GPSLocation> {

		if (!this.requestedBefore) {
			Cookies.set("GeoLocationAllowed", "TRUE");
			this.requestedBefore = true;
		}

		if (!navigator.geolocation) throw new Error(`Je locatie kon niet bepaald worden.`);

		return new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition((pos) => {
				resolve(new GPSLocation(pos.coords.latitude, pos.coords.longitude));
			}, (err) => this.handleError(err, reject), { enableHighAccuracy: true});
		});
	}

	private handleError(error: PositionError, reject: (reason: ServerError) => void) {
		switch (error.code) {
			case error.PERMISSION_DENIED:
				reject(new ServerError(GeoLocationError.PermissionDenied, `Je moet toelating geven om je locatie te delen.`));
				break;

			case error.POSITION_UNAVAILABLE:
				reject(new ServerError(GeoLocationError.Other, `Je locatiegegevens zijn momenteel niet beschikbaar.`));
				break;

			case error.TIMEOUT:
				reject(new ServerError(GeoLocationError.Other, `Je locatiegegevens zijn momenteel niet beschikbaar.`));
				break;

			default:
				reject(new ServerError(GeoLocationError.Other, `Een onbekende error heeft plaatsgevonden.`));
				break;
		}
	}
}
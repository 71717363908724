import React, { useEffect, useCallback } from "react"
import { useRequiredContext, useOptionalState } from "../util";
import { DatabaseContext } from "./DatabaseContext";
import { BearDTO, QuestionList } from "shared";
import { ImagePreview } from "./ImagePreview";
import { DistanceSubtext } from "./DistanceSubtext";
import { AgeSubtext } from "./AgeSubtext";

export interface IBearCardProps {
	bear: BearDTO;
	distance?: number;
	pictureUrl?: string;
}


export const BearCard: React.FC<IBearCardProps> = (props) => {

	const database = useRequiredContext(DatabaseContext);

	const bear = props.bear;
	const [pictureUrl, setPictureUrl] = useOptionalState<string>();

	let sourcePictureUrl = bear.pictureUrl;
	if (sourcePictureUrl.length === 0 && props.pictureUrl !== undefined) {
		sourcePictureUrl = props.pictureUrl;
	}

	let questionIndex = bear.questionIndex;
	const question = QuestionList[questionIndex].cardQuestion;

	const loadBearPicture = useCallback(async () => {
		if (sourcePictureUrl.startsWith("http") || sourcePictureUrl.startsWith("blob") || sourcePictureUrl.startsWith("data")) {
			setPictureUrl(sourcePictureUrl);
		}
		else {
			setPictureUrl(await database.getPictureUrl(sourcePictureUrl));
		}
	}, [database, setPictureUrl, sourcePictureUrl]);

	useEffect(() => {
		loadBearPicture();
	});

	let bonusText = props.children;

	// if we have distance specified, we override the bonus text
	let distanceText = <span></span>;
	if (props.distance !== undefined) {
		distanceText = <DistanceSubtext distance={props.distance} />;
	}


	return (
		<div className="bear-card">
			<div className="bear-title">
				{bear.name}
			</div>
			<AgeSubtext registerTime={props.bear.registerTime} />
			<div className="bear-subtitle">
				<div className="bear-subtitle-item">{question} {bear.questionAnswer}</div>
			</div>
			<div className="bear-subtitle">
				{distanceText}
			</div>
			<div className="bear-subtitle">
				{bonusText}
			</div>
			<div style={{height: 15}}/>
			<div className="bear-image">
				{pictureUrl && <ImagePreview src={pictureUrl} />}
			</div>
		</div>
	)
};


import React, { useEffect, useCallback, useState, } from "react"
import { useRequiredContext, useOptionalState } from "../util";
import { UserContext } from "./UserProvider";
import { LoadSpinner } from "./LoadSpinner";
import QRCode from 'qrcode';
import { Data } from "../util";
import { CenterContainer } from "./CenterContainer";
import { useHistory } from "react-router-dom";
import { BearButton } from "./BearButton";
import { Box } from "./Box";
import { QrCanvas } from "./QrCanvas";

export const PrintBear: React.FC = () => {

	const history = useHistory();

	const user = useRequiredContext(UserContext);

	const [qr, setQr] = useOptionalState<string>();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useOptionalState<Error>();

	const loadOwnedBear = useCallback(async () => {
		try {
			const userData = await user.getOwnedBears();
			if (userData.ownedBears.length > 0) {
				const bear = userData.ownedBears[0].bear;
				
				if (bear === null) return;
				const url = Data.baseUrl + "find/" + bear.id;

				const imageUrl = await QRCode.toDataURL(url, {width: 450});

				// now generate a canvas and fill it

				
				setQr(imageUrl);
			}
			else {
				setError(new Error("Je hebt momenteel geen beer geregistreerd! Je kunt dus geen QR-code afdrukken."));
			}
		}
		catch (err) {
			setError(err);
		}
		setLoading(false);
	}, [user, setQr, setError]);

	useEffect(() => {
		console.log("Load owned bear!");
		loadOwnedBear();

	}, [loadOwnedBear]);

	if (error !== null) {
		return (
			<CenterContainer backgroundColor="#FFFFFF">
				<Box>
					<p>
						{error.message}
					</p>
					<div><BearButton onClick={() => history.push("/user")}>Registreer Beer</BearButton></div>
				</Box>
			</CenterContainer>
		);
	}

	if (loading || qr === null) {
		return <CenterContainer backgroundColor="#FFFFFF"><LoadSpinner /></CenterContainer>;
	}

	return (
		<div style={{textAlign: "center"}}>
			<QrCanvas qrImageUrl={qr} />
		</div>
	  );
};


import React, { } from "react"
import { BearButton } from "./BearButton";

const Paw = require("./images/claw.png");

export interface IBearPawButtonProps {
	text?: string;
	onClick: () => void;
}

export const BearPawButton: React.FC<IBearPawButtonProps> = (props) => {
	return (
		<BearButton onClick={props.onClick}>{props.text && <span className="paw-text">{props.text} </span>}<img src={Paw} alt="paw" style={{width: "40px", height: "40px"}}></img></BearButton>
	);
};

import React, { useCallback, ReactNode, useState, useRef } from "react"
import { BackgroundProvider } from "./BackgroundProvider";
import GoogleMapReact, { MapOptions, Maps, ChangeEventValue } from 'google-map-react';
import { GPSLocation } from "shared";
import { BearMapHeader } from "./BearMapHeader";
import { useHistory } from "react-router-dom";
import { BearMapMarkerData, BearMarker } from "./BearMapMarker";
import { useOptionalState } from "../util";
import { Box } from "./Box";
import { BearButton } from "./BearButton";
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from "@material-ui/core";

export type latlng = {
	lat: number;
	lng: number;
}

export interface GPSBoundingBox {
	nw: GPSLocation;
	ne: GPSLocation;
	sw: GPSLocation;
	se: GPSLocation;
}

export interface BearMapProps {
	initialLocation: GPSLocation;
	onRefresh(bounds: GPSBoundingBox): void;
	bears: BearMapMarkerData[];
}

export const BearMap: React.FC<BearMapProps> = (props) => {

	const history = useHistory();

	const { bears, initialLocation, onRefresh } = props;

	// get current location
	const coords = { lat: initialLocation.latitude, lng: initialLocation.longitude };

	const [bearCard, setBearCard] = useOptionalState<ReactNode>();
	const [bearClicked, setBearClicked] = useState(false);

	const createMapOptions = (maps: Maps) => {
		const options: MapOptions = {
			gestureHandling: "greedy",
			minZoom: 13.0,

		}
		return options;
	}

	const onBack = useCallback(() => {
		history.push("/home");
	}, []);

	const showBearCard = useCallback((view: ReactNode) => {
		setBearCard(view);
		setBearClicked(true);
	}, [setBearCard, setBearClicked]);

	function toGPSLocation(latlng: latlng) {
		return new GPSLocation(latlng.lat, latlng.lng);
	}

	const onChange = useCallback((evt: ChangeEventValue) => {
		console.log(evt);
		onRefresh({
			nw: toGPSLocation(evt.bounds.nw),
			ne: toGPSLocation(evt.bounds.ne),
			se: toGPSLocation(evt.bounds.se),
			sw: toGPSLocation(evt.bounds.sw),
		});
	}, [onRefresh]);


	return (
		<BackgroundProvider className="home-background">
			<div style={{ height: '100vh', width: '100%' }}>
				<BearMapHeader onBack={onBack} onRefresh={() => {}} showTutorialText={!bearClicked} />
				<GoogleMapReact
				onChange={onChange}
					options={createMapOptions}
					bootstrapURLKeys={{ key: "AIzaSyDQujkOp78kVty4TedYJFtK5kvPSNOX-P0" }}
					defaultCenter={coords}
					defaultZoom={19}
				>
					{
						bears.map((bear, idx) => {
							return (
								<BearMarker
								onBearCard={showBearCard}
								lat={bear.location.latitude}
								lng={bear.location.longitude}
								data={bear}
								key={idx}
							/>
							);
						})
					}

				</GoogleMapReact>
			</div>
			<div style={{position: "absolute", top: 0, left: 0, marginLeft: "auto", marginRight: "auto", zIndex: 2, width: "100%"}}>
				<div className="content">
				{
					bearCard && 
					<Box>
						<div style={{position: "absolute", right: 40, top: 26}}>
						<IconButton style={{padding: 0}} onClick={() => setBearCard(null)} color="inherit"><CloseIcon fontSize="large" /></IconButton>
						</div>
						{bearCard}
						<div style={{textAlign: "center"}}>
						<BearButton onClick={() => setBearCard(null)}>Terug</BearButton>
						</div>
					</Box>
				}
</div>
			</div>
		</BackgroundProvider>
	);
};

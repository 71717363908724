import React, { useCallback, useState, } from "react"
import { useRequiredContext, useOptionalState } from "../util";
import { DatabaseContext } from "./DatabaseContext";
import { CatchExistingBearInput, CatchBearError } from "shared";
import { useHistory } from "react-router-dom";
import { LoadSpinner } from "./LoadSpinner";
import { Box } from "./Box";

import { RegisterBearUploadForm, IPhotoData } from "./RegisterBearUploadForm";
import { Localization } from "../localization";
import { BearPawButton } from "./BearPawButton";
import { CenterContainer } from "./CenterContainer";
import { ExistingBearList, BearList } from "./ExistingBearList";

export const CatchWildBear: React.FC = (props) => {

	const history = useHistory();

	const database = useRequiredContext(DatabaseContext);

	const [photoData, setPhotoData] = useOptionalState<IPhotoData>();
	const [catching, setCatching] = useState(false);
	const [caught, setCaught] = useState(false);
	const [caughtWild, setCaughtWild] = useState(false);
	const [firstCaught, setFirstCaught] = useState(false);
	const [suggestions, setSuggestions] = useOptionalState<BearList>();
	const [error, setError] = useOptionalState<Error>();

	const tryCatchWildBear = useCallback(async (newPhotoData: IPhotoData, forceNewBear: boolean) => {
		try {
			setCatching(true);
			setPhotoData(newPhotoData);
			setSuggestions(null);

			const attempt = await database.tryCatchWildBear(newPhotoData.file, newPhotoData.location, forceNewBear);

			// there are bears around - ask if it is one of those!
			if (attempt.suggestionsFound) {
				if (attempt.ownedBears === undefined || attempt.wildBears === undefined) throw new Error(`Er is een fout opgetreden op de server (EXPECTED_SUGGESTIONS). Probeer het aub opnieuw.`);
				setSuggestions({
					bears: attempt.ownedBears,
					wildBears: attempt.wildBears,
				});
			}
			else {
				if (attempt.caughtBear === undefined) throw new Error(`Er is een fout opgetreden op de server (EXPECTED_CATCH). Probeer het aub opnieuw.`);
				setCaught(true);
				setFirstCaught(attempt.caughtBear.first);
			}

			setCatching(false);
		}
		catch (err) {
			setCatching(false);
			setError(err);

		}
	}, [setPhotoData, setCatching, setCaught, setError, database, setSuggestions, setFirstCaught]);

	const catchExistingBear = useCallback(async (wild: boolean, bearId: string) => {
		const input: CatchExistingBearInput = {
			wild: wild,
			bearId: bearId,
		};

		try {
			setCatching(true);
			await database.catchExistingBear(input);
			setCaught(true);
			setCaughtWild(wild);
			setSuggestions(null);
			setCatching(false);
		}
		catch (err) {
			setCatching(false);
			if (err.code === CatchBearError.BearDeleted) setError(new Error(`Helaas is deze beer ondertussen verwijderd. Je kunt hem niet meer vangen!`));
			else if (err.code === CatchBearError.AlreadyCaught) setError(new Error(`Je hebt deze beer al eerder gevangen!`));
			else setError(err);

		}
	}, [setCatching, setError, setCaught, database, setSuggestions, setCaughtWild]);


	if (photoData === null) {
		return (
			<Box>
				<RegisterBearUploadForm onSubmit={(photoData) => tryCatchWildBear(photoData, false)} question={Localization.catchWildBearUploadQuestion} buttonText="Vang Beer!" />
			</Box>
		);
	}

	if (error !== null) {
		return (
			<CenterContainer>
				<Box>
					<p>
						{error.message}
					</p>
					<p>
						Klik op de berenpoot om naar het hoofdmenu te gaan.
					</p>
					<p>
						<BearPawButton onClick={() => history.push("/home")} />
					</p>
				</Box>
			</CenterContainer>
		);
	}

	if (catching) {
		return <LoadSpinner />;
	}

	if (suggestions !== null) {
		return (
			<div>
				<Box>
					Staat jouw beer in dit lijstje?
				</Box>
				<ExistingBearList list={suggestions} onPick={catchExistingBear} onNone={() => tryCatchWildBear(photoData, true)} pickText="Dit is 'm!" noneText="Nee, mijn foto is van een andere beer"/>
			</div>
		);
	}

	const caughtWildBearText = caughtWild ? Localization.caughtRealWildBearText : Localization.caughtOwnedWildBearText;

	return (
		<Box>
			<div>{caughtWildBearText}</div>
			{firstCaught && <div>Proficiat: je was de eerste persoon die deze beer ving!</div>}
			<div>Klik op de berenpoot om naar je verzameling te gaan.</div>
			<div><BearPawButton onClick={() => history.push("/collection")} /></div>
		</Box>
	)
};


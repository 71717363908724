import React, { useEffect, useCallback } from "react"
import { useRequiredContext, useOptionalState, usePromise } from "../util";
import { DatabaseContext } from "./DatabaseContext";
import { CaughtWildBearDTO, WildBearDTO } from "shared";
import { ImagePreview } from "./ImagePreview";
import { AgeSubtext } from "./AgeSubtext";

export interface IWildBearCardProps {
	bear: WildBearDTO;
}

export const WildBearCard: React.FC<IWildBearCardProps> = (props) => {

	const database = useRequiredContext(DatabaseContext);

	const bear = props.bear;
	const pictureUuid = bear.pictureUuid;

	const loadBearPicture = useCallback(async () => {
		return database.getPictureUrl(pictureUuid);
	}, [database, pictureUuid]);

	const time = bear.time;
	const dateString = new Intl.DateTimeFormat("nl-BE").format(time);

	const [pictureUrl] = usePromise(loadBearPicture, null);


	useEffect(() => {
		loadBearPicture();
	});

	return (
		<div className="bear-card">
			<div className="bear-title">
				Wilde Beer
			</div>
			<AgeSubtext registerTime={bear.time} />
			{props.children}
			<div style={{height: 15}}/>
			<div className="bear-image">
				{pictureUrl && <ImagePreview src={pictureUrl} />}
			</div>
		</div>
	)
};

export interface ICaughtWildBearCardProps {
	bear: CaughtWildBearDTO;
}

export const CaughtWildBearCard: React.FC<ICaughtWildBearCardProps> = (props) => {

	const database = useRequiredContext(DatabaseContext);

	const bear = props.bear;
	const pictureUuid = bear.pictureUuid;
	const [pictureUrl, setPictureUrl] = useOptionalState<string>();

	const time = bear.time;
	const dateString = new Intl.DateTimeFormat("nl-BE").format(time);

	const loadBearPicture = useCallback(async () => {
		setPictureUrl(await database.getPictureUrl(pictureUuid));
	}, [database, setPictureUrl, pictureUuid]);

	useEffect(() => {
		loadBearPicture();
	});

	return (
		<div className="bear-card">
			<div className="bear-title">
				Wilde Beer
			</div>
			{bear.first && <div className="bear-subtitle">Als eerste gevangen!</div>}
			<div className="bear-subtitle-item" style={{ flexGrow: 1 }}>Gevangen op {dateString}</div>
			<div style={{height: 15}}/>
			<div className="bear-image">
				{pictureUrl && <ImagePreview src={pictureUrl} />}
			</div>
		</div>
	)
};


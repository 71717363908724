import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Home, DatabaseProvider, PopupProvider, PrintBear, LoginArea, FindBearMenu, BackgroundProvider, CatchBearMenu, GeoLocationContext, ProfileMenu, CollectionMenu, CatchWildBearMenu, BearMapMenu } from './components';
import { SplashScreen } from './components/SplashScreen';
import { GeoLocation } from './util';

function App() {

  const geolocation = new GeoLocation();

  return (
    <GeoLocationContext.Provider value={geolocation}>
   <PopupProvider>
    <DatabaseProvider>
          <Router /*basename={'berenjacht'}*/>
        <main>
          <Route exact path="/" render={() => <SplashScreen />} />
          <Route path="/home" render={() => <Home />} />
          <Route path="/collection" render={() => <CollectionMenu />} />
          <Route path="/user" render={() => <ProfileMenu />} />
          <Route path="/qr" render={() => <BackgroundProvider className="home-white"><LoginArea showLogout={false} backgroundColor="#FFFFFF" centered={false}><PrintBear /></LoginArea></BackgroundProvider>} />
          <Route path="/find/:bearId" render={() => <BackgroundProvider className="home-background"><FindBearMenu /></BackgroundProvider>} />
          <Route path="/catch/:bearId" render={() => <CatchBearMenu />} />
          <Route path="/catchWildBear" render={() => <CatchWildBearMenu />} />
          <Route path="/map" render={() => <BearMapMenu />} />
        </main>
      </Router>
    </DatabaseProvider>
  </PopupProvider>
  </GeoLocationContext.Provider>
  );
}

export default App;

import React, {  } from "react"
import { LoginArea } from "./LoginArea";
import { BackgroundProvider } from "./BackgroundProvider";
import { useParams } from "react-router-dom";
import { CatchBear } from "./CatchBear";


export const CatchBearMenu: React.FC = () => {

	const { bearId } = useParams();

	return (
		<BackgroundProvider className="home-background">
			<LoginArea>
				<CatchBear bearId={bearId}/>
			</LoginArea>
		</BackgroundProvider>
	)
};

import React, { useCallback, useState, useEffect, useContext } from "react"
import { GPSLocation, GeohashData, GetBearsInVincinityInput, CaughtScanDTO } from "shared";
import { useHistory } from "react-router-dom";
import { useRequiredContext, useOptionalState, GeoLocationError } from "../util";
import { DatabaseContext } from "./DatabaseContext";
import { PopupContext } from "./PopupContext";
import { GeoLocationContext } from "./GeoLocationContext";
import { BearButton } from "./BearButton";
import { BearCard } from "./BearCard";
import { WildBearCard } from "./WildBearCard";
import { DistanceSubtext } from "./DistanceSubtext";
import { BearMap, GPSBoundingBox } from "./BearMap";
import { BearMapMarkerData } from "./BearMapMarker";
import { LoadSpinner } from "./LoadSpinner";
import { CenterContainer } from "./CenterContainer";
import { Box } from "./Box";
import { UserContext } from "./UserProvider";
const ngeohash = require("ngeohash");

export type CollectedBears = {
	bearIds: string[];
	wildBearIds: string[];
}

export const BearMapLoader: React.FC = () => {

	const history = useHistory();

	const database = useRequiredContext(DatabaseContext);
	const popup = useRequiredContext(PopupContext);
	const location = useRequiredContext(GeoLocationContext);

	const allowed = location.isActivated();

	const [bears, setBears] = useState<CaughtScanDTO>({
		bears: [],
		wildBears: [],
		geoHashes: [],
	});
	const [neverFoundBears, setNeverFoundBears] = useState(true);

	const [blocked, setBlocked] = useState(false);
	const [collectedBears, setCollectedBears] = useState<CollectedBears>({ bearIds: [], wildBearIds: [] });

	const [first, setFirst] = useState(true);

	const [failed, setFailed] = useState(false);
	const [currentLocation, setCurrentLocation] = useOptionalState<GPSLocation>();
	const [initialLocation, setInitialLocation] = useOptionalState<GPSLocation>();

	const user = useContext(UserContext);


	const findBears = useCallback(async (coords: GPSLocation, radius: number) => {
		console.log("Try to fetch bears...");
		try {

			const boundingBox = coords.getBoundingBox(radius);
			console.log(boundingBox);
			let bboxes = ngeohash.bboxes(boundingBox.minPoint.latitude, boundingBox.minPoint.longitude, boundingBox.maxPoint.latitude, boundingBox.maxPoint.longitude, GeohashData.normalPrecision);
			console.log("ALL BBOXES: ");
			console.log(bboxes);
			const input: GetBearsInVincinityInput = {
				geoHashes: bboxes,
				radiusInKm: radius,
			};

			// remove all the geohashes we did previously
			input.geoHashes = input.geoHashes.filter(hash => !bears.geoHashes.includes(hash));
			console.log("SCAN THESE GEOHASHES:");
			console.log(input.geoHashes);
			if (input.geoHashes.length === 0) return;


			const simpleBears = await database.backend.getBearsInVincinity(input);
			const newBears: CaughtScanDTO = {
				bears: simpleBears.bears.map((bear) => ({ bear: bear, caught: false })),
				wildBears: simpleBears.wildBears.map((bear) => ({ wildBear: bear, caught: false })),
				geoHashes: simpleBears.geoHashes,
			};
			const allBears: CaughtScanDTO = {
				bears: [...bears.bears, ...newBears.bears],
				wildBears: [...bears.wildBears, ...newBears.wildBears],
				geoHashes: [...bears.geoHashes, ...newBears.geoHashes],
			};


			console.log("Found " + (newBears.bears.length + newBears.wildBears.length) + " new bears, " + (allBears.bears.length + allBears.wildBears.length) + " total!");

			setCurrentLocation(coords);
			setBears(allBears);
			setNeverFoundBears(false);
		}
		catch (err) {
			if (err.code === GeoLocationError.PermissionDenied) setBlocked(true);
			else {
				popup.show("Zoeken Mislukt", err.message);
				setFailed(true);
			}
		}

	}, [setBears, popup, database, setCurrentLocation, setNeverFoundBears, bears]);

	const findBearsAtCurrentLocation = useCallback(async () => {
		try {

			// if we have a user, we update the caught-data by downloading the collection
			if (user !== undefined) {
				const collection = await user.getCollection(0, 1000);
				const bearIds = collection.caughtBears.map((bear) => bear.bear.id);
				const wildBearIds = collection.caughtWildBears.map((bear) => bear.id);
				setCollectedBears({
					bearIds: bearIds,
					wildBearIds: wildBearIds,
				});
				console.log("COLLECTED BEARS:");
				console.log(bearIds);
				console.log(wildBearIds);
			}

			const coords = await location.getLocation();
			setInitialLocation(coords);
			await findBears(coords, 1.5);
		}
		catch (err) {
			if (err.code === GeoLocationError.PermissionDenied) setBlocked(true);
			else {
				popup.show("Zoeken Mislukt", err.message);
				setFailed(true);
			}
		}
	}, [setInitialLocation, setFailed, findBears, location, popup, setCollectedBears, user]);

	const updateBounds = useCallback((bounds: GPSBoundingBox) => {
		console.log("Update bounds!");

		// convert to a central location and a range
		const radius = Math.max(GPSLocation.distance(bounds.ne, bounds.sw), GPSLocation.distance(bounds.nw, bounds.se));
		const center = new GPSLocation((bounds.ne.latitude + bounds.sw.latitude) * 0.5, (bounds.ne.longitude + bounds.nw.longitude) * 0.5);
		findBears(center, radius);
	}, [findBears]);

	useEffect(() => {
		if (first) {
			setFirst(false);
			findBearsAtCurrentLocation();
		}
	}, [findBearsAtCurrentLocation, first]);

	if (failed) {
		return (
			<CenterContainer>
				<Box>
					<div>Er is iets misgegaan.</div>
					<div><BearButton onClick={() => history.push("/home")}>Terug</BearButton></div>
				</Box>
			</CenterContainer>
		);
	}

	if (blocked) {
		return (
			<CenterContainer>
				<Box>
					<div>Je hebt geen toelating gegeven om je locatie te gebruiken. Daardoor kunnen we geen berenkaart tonen.</div>
					<div><BearButton onClick={() => history.push("/home")}>Terug</BearButton></div>
				</Box>
			</CenterContainer>
		);
	}

	if (neverFoundBears || currentLocation === null || initialLocation === null || !allowed) {
		return <LoadSpinner />;
	}

	let bearViews: BearMapMarkerData[] = [];

	for (const data of bears.bears) {
		const distanceFromUs = GPSLocation.distance(data.bear.location, currentLocation);
		const caught = collectedBears.bearIds.includes(data.bear.id);
		bearViews.push({
			view: (<BearCard bear={data.bear} distance={distanceFromUs}>{!caught && <span>Nog niet gevangen!</span>}</BearCard>),
			distanceFromUs: distanceFromUs,
			location: data.bear.location,
			caught: caught,
		});
	}

	if (bears.wildBears) {
		for (const data of bears.wildBears) {
			const caught = collectedBears.wildBearIds.includes(data.wildBear.id);
			const distanceFromUs = GPSLocation.distance(data.wildBear.location, currentLocation);
			bearViews.push({
				view: (<WildBearCard bear={data.wildBear}>
					<div>
						<DistanceSubtext distance={distanceFromUs} />
						{!caught && <div className="bear-subtitle">Nog niet gevangen!</div>}
					</div>
				</WildBearCard>),
				distanceFromUs: distanceFromUs,
				location: data.wildBear.location,
				caught: caught,
			});
		}
	}

	bearViews.sort((a, b) => a.distanceFromUs - b.distanceFromUs);


	if (bearViews.length === 0) {
		return (
			<CenterContainer>
				<Box>
					<div>Helaas zijn er geen beren in je buurt gevonden. Misschien kun je zelf een beer voor je raam zetten en hem registreren?</div>
					<div><BearButton onClick={() => history.push("/user")}>Registreer Beer</BearButton></div>
				</Box>
			</CenterContainer>
		);
	}

	return (
		<BearMap initialLocation={initialLocation} onRefresh={updateBounds} bears={bearViews} />
	);
};

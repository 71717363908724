import { makeStyles, Button } from "@material-ui/core";
import React from "react";


export interface IBearButtonProps {
	onClick?: () => void;
	color?: "primary" | "secondary";
	type?: "submit";
	disabled?: boolean;
	component?: "span";
	bearColored?: boolean;
	style?: React.CSSProperties;
	noButton?: boolean;
}

const useStyles = makeStyles({
	primary: {
	  background: 'linear-gradient(45deg, #FFFFFF 30%, #E5E5E5 90%)',
	  fontFamily: "Riffic Free Bold",
	  fontSize: "18px",
	  border: 0,
	  borderRadius: 10,
	  //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
	  boxShadow: '5px 5px 0px rgba(255, 255, 255, .2)',
	  color: '#0259a0',
	  padding: '10px 10px',
	  marginTop: "10px",
	  marginBottom: "10px",
	},

	secondary: {
		background: 'linear-gradient(45deg, #dc004e 30%, #dc004e 90%)',
		fontFamily: "Riffic Free Bold",
		fontSize: "18px",
		border: 0,
		borderRadius: 10,
		//boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
		boxShadow: '5px 5px 0px #dc004e44',
		color: 'white',
		padding: '10px 10px',
		marginTop: "10px",
		marginBottom: "10px",
	  },

	  bear: {
		fontSize: "32px",
		lineHeight: "50%",
		fontFamily: "Brady Bunch",
		marginRight: "10px",
		marginTop: "10px",
		marginBottom: "10px",
		color: "#4a72ad",
	  }
  });
  

export const BearButton: React.FC<IBearButtonProps> = (props) => {

	const classes = useStyles();

	const color = props.color || "primary";
	let buttonClass = (color === "primary" ? classes.primary : classes.secondary);
	if (props.bearColored) buttonClass += " " + classes.bear;

	if (props.noButton !== undefined) {
		return <div className={buttonClass} onClick={props.onClick} style={props.style}>{props.children}</div>;
	}
	else {
		return <Button className={buttonClass} onClick={props.onClick} {...props}>{props.children}</Button>;
	}
};


import React, { useEffect, useState } from "react"
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { AuthInfo } from "../database";
import { LoadSpinner } from "./LoadSpinner";
import { AuthContext } from "./AuthProvider";

export const OptionalAuthProvider: React.FC = (props) => {

  const [loaded, setLoaded] = useState(false);
  const [authInfo, setAuthInfo] = useState<AuthInfo | null>(null);

  useEffect(() => {
    let mounted = true;

    const unregisterAuthObserver = app.auth().onAuthStateChanged((user) => {

      const newLoaded = true;
      const newSignedIn = !!user;

      if (!mounted) return;
      if ((authInfo !== null) === newSignedIn && loaded === newLoaded) return;

      
      if (user) {
        setAuthInfo({
          id: user.uid,
          name: user.displayName || "Onbekend",
        });
      }
      else {
        setAuthInfo(null);
      }
      setLoaded(true);
    });

    return () => {
      mounted = false;
      unregisterAuthObserver();
    }
  }, [authInfo, loaded]);

  if (!loaded) {
    return <LoadSpinner />;
  }

  if (authInfo === null) {
    return <span>{props.children}</span>;
  }

  return (
    <AuthContext.Provider value={authInfo}>
        {props.children}
    </AuthContext.Provider>
  );
};

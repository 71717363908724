import React, { useContext } from "react"
import { AuthContext } from "./AuthProvider";
import { UserProvider } from "./UserProvider";

export const OptionalUserProvider: React.FC = (props) => {
 
  const authInfo = useContext(AuthContext);

  if (authInfo !== undefined) {
    return <UserProvider>{props.children}</UserProvider>;
  }
  else {
    return <span>{props.children}</span>;
  }
};

import React, { } from "react"

export interface IDistanceSubtextProps {
	distance: number;
}


export const DistanceSubtext: React.FC<IDistanceSubtextProps> = (props) => {
	
	const distance = props.distance;

	let distanceString: string = "";
	if (distance > 1) {
		distanceString = Math.ceil(distance) + "km";
	}
	else {
		const meters = Math.ceil(distance / 10.0) * 100;
		distanceString = meters + "m";
	}

	return <div className="bear-subtitle-item" style={{flexGrow: 1}}>Afstand: {distanceString}</div>;
};


import React, {  createContext } from "react"


export interface IBackgroundProviderProps {
  className: string;
}


export const BackgroundClassNameContext = createContext<string>("home-background");

export const BackgroundProvider: React.FC<IBackgroundProviderProps> = (props) => {
  return (
    <BackgroundClassNameContext.Provider value={props.className}>
      {props.children}
    </BackgroundClassNameContext.Provider>
  )
};

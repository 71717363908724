

function degreesToRadians(degrees: number) {
	return (degrees * Math.PI)/180;
}
function radiansToDegrees(radians: number): number
{
    return 180.0 * radians / Math.PI;
}


// Semi-axes of WGS-84 geoidal reference
const WGS84_a = 6378137.0; // Major semiaxis [m]
const WGS84_b = 6356752.3; // Minor semiaxis [m]

// Earth radius at a given latitude, according to the WGS-84 ellipsoid [m]
function WGS84EarthRadius(lat: number): number
{
    // http://en.wikipedia.org/wiki/Earth_radius
    const An = WGS84_a * WGS84_a * Math.cos(lat);
    const Bn = WGS84_b * WGS84_b * Math.sin(lat);
    const Ad = WGS84_a * Math.cos(lat);
    const Bd = WGS84_b * Math.sin(lat);
    return Math.sqrt((An*An + Bn*Bn) / (Ad*Ad + Bd*Bd));
}


export type GeoPoint = {
	latitude: number;
	longitude: number;
}


export interface IBoundingBox {
	minPoint: GPSLocation;
	maxPoint: GPSLocation;
	halfSideInKm: number;
	center: GPSLocation;
}

export class GPSLocation {

	latitude: number;
	longitude: number;

	constructor(latitude: number, longitude: number) {
		this.latitude = latitude;
		this.longitude = longitude;
	}

	// 'halfSideInKm' is the half length of the bounding box you want in kilometers.
	getBoundingBox(halfSideInKm: number): IBoundingBox {            
		const point = this;

		// Bounding box surrounding the point at given coordinates,
		// assuming local approximation of Earth surface as a sphere
		// of radius given by WGS84
		const lat = degreesToRadians(point.latitude);
		const lon = degreesToRadians(point.longitude);
		const halfSide = 1000 * halfSideInKm;

		// Radius of Earth at given latitude
		const radius = WGS84EarthRadius(lat);
		// Radius of the parallel at given latitude
		const pradius = radius * Math.cos(lat);

		const latMin = lat - halfSide / radius;
		const latMax = lat + halfSide / radius;
		const lonMin = lon - halfSide / pradius;
		const lonMax = lon + halfSide / pradius;

		return { 
			minPoint: new GPSLocation(radiansToDegrees(latMin), radiansToDegrees(lonMin)),
			maxPoint: new GPSLocation(radiansToDegrees(latMax), radiansToDegrees(lonMax)),
			center: new GPSLocation(point.latitude, point.longitude),
			halfSideInKm: halfSideInKm,
		};
	}

	static fromGeopoint(p: GeoPoint) {
		return new GPSLocation(p.latitude, p.longitude);
	}

	static fromDegrees(latitude: number[], longitude: number[]) {
		return new GPSLocation(this.convertDegreesToNumber(latitude), this.convertDegreesToNumber(longitude));
	}

	/**
	 * Calculates the distance, in kilometers, between two locations, via the
	 * Haversine formula. Note that this is approximate due to the fact that
	 * the Earth's radius varies between 6356.752 km and 6378.137 km.
	 *
	 * @param {Object} location1 The first location given as .latitude and .longitude
	 * @param {Object} location2 The second location given as .latitude and .longitude
	 * @return {number} The distance, in kilometers, between the inputted locations.
	 */
	static distance(location1: GPSLocation, location2: GPSLocation) {
		const radius = 6371; // Earth's radius in kilometers
		const latDelta = degreesToRadians(location2.latitude - location1.latitude);
		const lonDelta = degreesToRadians(location2.longitude - location1.longitude);
	
		const a = (Math.sin(latDelta / 2) * Math.sin(latDelta / 2)) +
				(Math.cos(degreesToRadians(location1.latitude)) * Math.cos(degreesToRadians(location2.latitude)) *
				Math.sin(lonDelta / 2) * Math.sin(lonDelta / 2));
	
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	
		return radius * c;
	}

	private static convertDegreesToNumber(lat: number[]) {
		let v = 0.0;
		if (lat.length >= 1) v += lat[0];
		if (lat.length >= 2) v += lat[1] / 60.0;
		if (lat.length >= 3) v += lat[2] / 3600.0;
		return v;
	}
}

import React from "react";
import { IconButton } from "@material-ui/core";
import { Box } from "./Box";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

export interface IHeaderProps {
	title: string;
}

export const Header: React.FC<IHeaderProps> = (props) => {

	const history = useHistory();
	
	return (
		<Box color="primary">
			<div className="box-title">{props.title}</div>
			<div className="box-back"><IconButton onClick={() => history.push("/home")} color="inherit"><ArrowBackIcon fontSize="large" /></IconButton></div>
		</Box>
	);
}
import React, { useState, useCallback } from "react"
import { RegisterBearUploadForm, IPhotoData } from "./RegisterBearUploadForm";
import {  QuestionList, BearDTO, RegisterBearError, WildBearSuggestion, ClaimWildBearError } from "shared";
import { useOptionalState, useRequiredContext } from "../util";
import { IBearData, BearDataForm } from "./BearDataForm";
import * as random from "random";
import { BearCard } from "./BearCard";
import { BearButton } from "./BearButton";
import { DatabaseContext } from "./DatabaseContext";
import { PopupContext } from "./PopupContext";
import { LoadSpinner } from "./LoadSpinner";
import { Localization } from "../localization";
import { Box } from "./Box";
import { ExistingBearList } from "./ExistingBearList";

interface IRegisterBearFormProps {
	onRegistered: () => void;
}

export const RegisterBearForm: React.FC<IRegisterBearFormProps> = (props) => {

	const database = useRequiredContext(DatabaseContext);
	const popup = useRequiredContext(PopupContext);

	const { onRegistered } = props;

	const [photoData, setPhotoData] = useOptionalState<IPhotoData>();
	const [bearData, setBearData] = useOptionalState<IBearData>();
	const [suggestions, setSuggestions] = useOptionalState<WildBearSuggestion[]>();

	const [uploading, setUploading] = useState(false);
	const [claiming, setClaiming] = useState(false);

	let bearDTO: BearDTO | null = null;
	if (photoData !== null && bearData !== null) {
		bearDTO = {
			id: "",
			name: bearData.bearName,
			questionIndex: bearData.questionIndex,
			questionAnswer: bearData.questionAnswer,
			pictureUrl: "",
			registerTime: Date.now(),
			location: photoData.location,
		};
	}

	const handlePhotoUrl = useCallback((newPhotoData: IPhotoData) => {
		setPhotoData(newPhotoData);
	}, [setPhotoData]);

	const handleBearData = useCallback((newBearData: IBearData) => {
		setBearData(newBearData);
	}, [setBearData]);


	function reset() {
		setPhotoData(null);
		setBearData(null);
	}

	const startUpload = useCallback(async () => {
		if (bearDTO === null || photoData === null) return;

		setUploading(true);

		// first, upload the picture
		try {
			const registeredBear = await database.registerBear(photoData.file, bearDTO);

			if (registeredBear.claimSuggestions.length > 0) {
				setUploading(false);
				setSuggestions(registeredBear.claimSuggestions);
			}

			// no suggestions, we are done
			else {
				popup.show(Localization.registeredBearTitle, Localization.registeredBearText);
				onRegistered();
			}
		}
		catch (err) {
			if (err.code === RegisterBearError.PictureUnavailable) popup.show("Foto Uploaden Mislukt", "Helaas is het uploaden van je foto mislukt. Probeer het opnieuw!");
			else if (err.code === RegisterBearError.RegisteredTooEarly) popup.show("Beer Registreren Mislukt", "Je hebt de voorbije dag al een beer geregistreerd. Je kunt maar één beer per dag toevoegen.");
			else popup.show("Registreren Mislukt!", err.message);
			onRegistered();
		}

	}, [bearDTO, popup, database, photoData, onRegistered, setSuggestions]);

	const claimBear = useCallback(async (wild: boolean, bearId: string) => {
		setClaiming(true);
		try {
			await database.claimWildBear(bearId);
			popup.show(Localization.registeredBearTitle, Localization.registeredBearText);

		}
		catch (err) {
			// even if the claiming fails, we are okay, since we already registered
			/*if (err.code === ClaimWildBearError.BearDeleted) popup.show("", "Helaas is het uploaden van je foto mislukt. Probeer het opnieuw!");
			else if (err.code === ClaimWildBearError.NoOwnBear) popup.show("Beer Registreren Mislukt", "Je hebt de voorbije dag al een beer geregistreerd. Je kunt maar één beer per dag toevoegen.");
			else popup.show("Registreren Mislukt!", err.message);*/
			popup.show(Localization.registeredBearTitle, Localization.registeredBearText);
			
		}
		onRegistered();
	}, [setClaiming, popup, database, onRegistered]);


	if (photoData === null) {
		return (
			<Box>
				<RegisterBearUploadForm
					onSubmit={handlePhotoUrl}
					question={Localization.registerFormUploadQuestion}
					buttonText="Volgende"
				/>
			</Box>
		);
	}
	
	if (bearData === null) {
		const questionIndex = random.int(0, QuestionList.length-1);
		return <Box><BearDataForm questionIndex={questionIndex} onSubmit={handleBearData} /></Box>;
	}

	if (uploading || bearDTO === null || claiming) {
		return <LoadSpinner/>;
	}
	
	if (suggestions !== null) {
		return (
			<div>
				<Box>
					Andere mensen hebben al foto's getrokken van beren in de omgeving. Is één van deze beren jouw beer?
				</Box>
				<ExistingBearList list={{bears: [], wildBears: suggestions}} onPick={claimBear} onNone={onRegistered} pickText="Dit is mijn beer!" noneText="Mijn beer is nieuw"/>
			</div>
		);
	}

	return (
		<Box>
			Dit is hoe andere mensen je beer zullen zien. Als je tevreden bent, bevestig dan onderaan.
			<BearCard bear={bearDTO} pictureUrl={photoData?.imageUrl}/>
			<p>
				<BearButton color="primary" onClick={reset}>Opnieuw Beginnen</BearButton>&nbsp;&nbsp;
				<BearButton color="secondary" onClick={startUpload} disabled={uploading}>Voltooien</BearButton>
			</p>

		</Box>
	);
};


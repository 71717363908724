import React, { useCallback } from "react"
import { createRequiredContext, useRequiredContext, usePromise } from "../util";
import { AuthContext } from "./AuthProvider";
import { DatabaseContext } from "./DatabaseContext";
import { LoadSpinner } from "./LoadSpinner";
import { BearButton } from "./BearButton";
import { CenterContainer } from "./CenterContainer";
import { Box } from "./Box";
import { useHistory } from "react-router-dom";
import { User } from "../database";

export const UserContext = createRequiredContext<User>();

export const UserProvider: React.FC = (props) => {

  const history = useHistory();

  const authInfo = useRequiredContext(AuthContext);
  const db = useRequiredContext(DatabaseContext);

  const loadUser = useCallback(async () => {
    const user = await db.loadUserFromFacebook(authInfo);
    return user;
  }, [db, authInfo]);

  const [user, error, loading] = usePromise(loadUser, null);

  if (loading) {
    return <LoadSpinner />;
  }

  if (error !== null) {
    return (
      <CenterContainer>
        <Box>
          <p>
            Login met Facebook mislukt. Probeer nog eens later.
					</p>
          <BearButton color="primary" onClick={() => history.push("/")}>Bezoek Website</BearButton>
        </Box>
      </CenterContainer>
    );
  }

  if (user === null) throw new Error(`Should never happen.`);

  return (
    <UserContext.Provider value={user}>
      {props.children}
    </UserContext.Provider>
  );
};

import React, { ReactNode } from "react"
import { GPSLocation } from "shared";

const BearFound = require("./images/bear-blue-stroke.png");
const BearNotFound = require("./images/bear-found-stroke.png");

export type BearMapMarkerData = {
	view: ReactNode;
	distanceFromUs: number;
	location: GPSLocation;
	caught: boolean;
}

interface BearMarkerProps {
	data: BearMapMarkerData;
	lat: number;
	lng: number;
	onBearCard(view: ReactNode): void;
}

export const BearMarker: React.FC<BearMarkerProps> = (props) => {
	return (
		<div style={{ position: 'absolute', transform: 'translate(-50%, -50%)' }} onClick={() => props.onBearCard(props.data.view)}>
			<img style={{ width: 24 }} src={props.data.caught ? BearFound : BearNotFound} alt="bear icon" />
		</div>
	);
	/*return (
		A
	</div>
	);*/
}
